import React, { useContext, useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import '../../style.css';
import myResumeEn from './en_CV_Hubert_Przypis.pdf';
import myResumePl from './Hubert_Przypis_CV.pdf';
import LanguageContext, { useTranslation } from '../../LanguageContext';
import Particle from '../Particle';

function Resume() {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();

  const [resumeUrl, setResumeUrl] = useState(
    language === 'en' ? myResumeEn : myResumePl,
  );

  useEffect(() => {
    setResumeUrl(language === 'en' ? myResumeEn : myResumePl);
  }, [language]);

  const downloadFileName =
    language === 'en' ? 'en_CV_Hubert_Przypis.pdf' : 'Hubert_Przypis_CV.pdf';
  const downloadButtonText = t('downloadCV');

  return (
    <Container fluid className="resumeSection">

      <Container>
        <div className="buttonContainer">
          <a href={resumeUrl} download={downloadFileName}>
            <Button variant="success" className="downloadButton">
              {downloadButtonText}
            </Button>
          </a>
        </div>
        <div className="resumeContainer">
          <embed
            src={resumeUrl}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        </div>
      </Container>
    </Container>
  );
}

export default Resume;
