import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { useTranslation } from '../../LanguageContext';
import { Link } from 'react-router-dom';
import Particle from '../Particle';
import '../../style.css';

function Projects() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [activeProject, setActiveProject] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (project) => {
    setActiveProject(project);
    setShow(true);
  };

  const projectsData = [
    {
      title: 'EBM dentysta',
      descriptionKey: 'ebmDescription',
      link: 'https://ebm-dentysta.pl',
    },
    {
      title: 'Pimp My Miniatures',
      descriptionKey: 'pimpDescription',
      link: 'https://pimpmyminiatures.pl/pl',
    },
    {
      title: 'Ofc my website :)',
      descriptionKey: 'hubertDescription',
      link: 'https://hubertprzypis.pl',
    },
    {
      title: 'MOL',
      descriptionKey: 'MarkizyDescription',
      link: 'https://markizy.mol-zamowienia.pl/login.php?path=/index.php',
    },
    {
      title: 'MOL',
      descriptionKey: 'PergoleDescription',
      link: 'https://pergole.mol-zamowienia.pl/login.php?path=/index.php',
    },
    {
      title: 'MOL',
      descriptionKey: 'MoskitieryDescription',
      link: 'https://moskitiery.mol-zamowienia.pl/login.php?path=/index.php',
    },
  ];

  return (
    <Container fluid className="projects-section">
      <Particle />

      <Container>
        <Row>
          <Col className="github-tile">
            <Card>
              <Card.Body>
                <Card.Title>{t('projectsTitle')}</Card.Title>
                <Card.Text>{t('findMoreText')}</Card.Text>
                <Button
                  variant="primary"
                  href="https://github.com/HuBiT55"
                  target="_blank"
                  className="mr-2"
                >
                  {t('githubButton')}
                </Button>
                <Link to="/OnlineProjects">
                  <Button variant="info">{t('ProjectInButton')}</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          {projectsData.map((project, index) => (
            <Col key={index} md={6}>
              <Card
                className="project-card"
                onClick={() => handleShow(project)}
              >
                <div className="iframe-overlay">
                  <iframe
                    src={project.link}
                    title={project.title}
                    className="iframe-background"
                    frameBorder="0"
                    scrolling="no"
                  ></iframe>
                  <div className="overlay"></div>
                </div>
                <Card.Body>
                  <Card.Title>{project.title}</Card.Title>
                  <Card.Text>{t(project.descriptionKey)}</Card.Text>
                  {project.link && (
                    <Button variant="info" href={project.link} target="_blank">
                      {t('visitButton')}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{activeProject && activeProject.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {activeProject && (
            <iframe
              src={activeProject.link}
              title={activeProject.title}
              className="iframe-modal"
              frameBorder="0"
              scrolling="no"
            ></iframe>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('closeButton')}
          </Button>
          {activeProject && (
            <Button variant="info" href={activeProject.link} target="_blank">
              {t('visitButton')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Projects;
