import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import gmailIcon from '../../Assets/gmail_icon.png';
import { useTranslation } from '../../LanguageContext.js';
import Particle from '../Particle';

function Contact() {
  const { t } = useTranslation();

  const handleEmailClick = () => {
    window.location.href = 'mailto:hubert.przypis.it@gmail.com';
  };

  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container>
        <Row>
          <Col md={6}>
            <h2 className="section-title">{t('contactTitle')}</h2>
            <p>{t('contactDescription')}</p>

            <a
              href="#"
              rel="noopener noreferrer"
              className="gmail-link"
              onClick={handleEmailClick}
            >
              <img src={gmailIcon} alt="Gmail Icon" className="gmail-icon" />
            </a>
          </Col>
          <Col md={6} className="contact-info">
            <h2 className="section-title">{t('contactInfoTitle')}</h2>
            <input
              type="text"
              value="hubert.przypis.it@gmail.com"
              readOnly
              onClick={(e) => e.target.select()}
              style={{ cursor: 'pointer', border: 'none', background: 'transparent' }}
            />
            <input
              type="text"
              value="kontakt@hubertprzypis.pl"
              readOnly
              onClick={(e) => e.target.select()}
              style={{ cursor: 'pointer', border: 'none', background: 'transparent' }}
            />
            <input
              type="text"
              value="hubert.przypis@proton.me"
              readOnly
              onClick={(e) => e.target.select()}
              style={{ cursor: 'pointer', border: 'none', background: 'transparent' }}
            />
            {/* <p>{t('contactPhoneNumber')}: +48 XXX XXX XXX</p> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Contact;